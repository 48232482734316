import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Resources from '../views/Resources.vue'
import DataScience from '../views/DataScience.vue'
import NotFound from '../views/NotFound.vue'
import Register from '../views/Register.vue'
import Login from '../views/Login.vue'
import Backend from '../views/web/back/Backend.vue'
import Frontend from '../views/web/front/Front.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView,
    meta: { title: 'Exorom' }
  },
  {
    path: '/:field/resources/:name',
    name: 'Resources',
    component: Resources,
    meta: { title: ':name' }
  },
  {
    path: '/datascience',
   name: 'DataScience',
    component: DataScience,
    meta: { title: 'Data Science' }
  },

  {
    path: '/register',
   name: 'Register',
    component: Register,
    meta: { title: 'Register' }
  },

  {
    path: '/login',
   name: 'Login',
    component: Login,
    meta: { title: 'Login' }
  },

  {
    path: '/backend/',
   name: 'Backend',
    component: Backend,
    meta: { title: 'Backend Roadmap' }
  },

  {
    path: '/frontend/',
   name: 'FrontEnd',
    component: Frontend,
    meta: { title: 'Frontend Roadmap' }
  },
  { path: '/:pathMatch(.*)*', component: NotFound },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
