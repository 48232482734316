<template>

    <v-container class="grey lighten-5">
    
        <v-row
          
          justify="center"
        >
          <v-col
            sm="3"
            offset-sm="3"
            md="3"
            offset-md="0"
          >
        
          <v-row 
          justify="center"
        >
        
    <v-img
          alt="Exorom LOGO"
          class="shrink mr-2"
          contain
          src="@/assets/img/logo.jpg"
          transition="scale-transition"
          width="80"
        />
     
          <h2  class="my-15">
       Create New Account
     </h2>
     </v-row>
      
          <v-form
        ref="form"
        
        lazy-validation
      >
     
      
    
        <v-text-field
          v-model="email"
          :rules="emailRules"
          label="E-mail"
          required
          outlined
        ></v-text-field>
    
        <v-text-field
      v-model="username"
      :counter="10"
      
      label="Username"
      required
      outlined
    ></v-text-field>
        <v-text-field
      v-model="password"
      :counter="10"
      
      label="Password"
      required
      outlined
    ></v-text-field>


        <v-btn
        
          color="primary"
          large
          style="width:100%;"
          @click="signup"
        >
        <v-progress-circular v-if="progress"
      indeterminate
      color="white"
    ></v-progress-circular>
<div v-if="progress==false">
         Create
         </div>
        </v-btn>
        <v-btn
        
          color="black"
          large
          style="width:100%;"
        
          class="mt-5"
          text
        >
         Reset Password
        </v-btn>
    
        <v-btn
        
       
     large
        style="width:100%;"
      
        class="mt-5"
        
      >
     Sign in  to Existing account
      </v-btn>
    
     
      </v-form>
           
          </v-col>
        </v-row>
        </v-container>
    
    
    
    </template>
    <script>
    var Parse = require('parse')
    
    export default{
        components:{
        
    },

    data() {
    return {
      progress:false,
      password: "",
      email: "",
      username:"",
      inputRules:[
        v=> v.length >= 3 || 'Minimum length is 3 characters'
      ],
       emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
      errorMsg : ''

    }
  },

    methods:{
      async signup(){
        this.progress = true;
          this.dialog1 = true;
        let email = this.email;
        let username = this.username;
        let password = this.password;
          let user = new Parse.User();
          user.set("username",username);
          user.set("email", email);
            user.set("password",password);
     
       // .then(() => {
        
         
      
          
      //  })
        //  .catch(e => {
         // alert(e.message);
        //});
         try {
    // Call the save method, which returns the saved object if successful
    user = await user.save();
    if (user !== null) {
      // Notify the success by getting the attributes from the "User" object, by using the get method (the id attribute needs to be accessed directly, though)
     window.location.href = '/';
    }
  } catch (error) {
    alert(`Error: ${error.message}`);
    this.progress = false;
  }

    }
    }
    
    
    
    }
    </script>