import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VHover } from 'vuetify/lib/components/VHover';
import { VImg } from 'vuetify/lib/components/VImg';
import { VParallax } from 'vuetify/lib/components/VParallax';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"id":"hero"}},[_c(VParallax,{attrs:{"dark":"","src":require("@/assets/img/bgHero.jpg"),"height":"750"}},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"10"}},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"12","md":"6","xl":"8"}},[_c('h1',{staticClass:"display-2 font-weight-bold mb-4"},[_vm._v("Clear Path to your dream job")]),_c('h1',{staticClass:"font-weight-light"},[_vm._v(" We are here with you step by step. ")])]),_c(VCol,{staticClass:"hidden-sm-and-down",attrs:{"cols":"12","md":"6","xl":"4"}})],1)],1)],1),_c('div',{staticClass:"svg-border-waves text-white"},[_c(VImg,{attrs:{"src":require("@/assets/img/borderWaves.svg")}})],1)],1),_c(VContainer,{staticClass:"mt-2",attrs:{"fluid":"","id":"features"}},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"10"}},[_c(VRow,{attrs:{"align":"center","justify":"space-around"}},_vm._l((_vm.items),function(i){return _c(VCol,{key:i.id,staticClass:"text-center",attrs:{"cols":"12","sm":"4"}},[_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c(VCard,{staticClass:"card",class:{ up: hover },attrs:{"shaped":"","elevation":hover ? 10 : 4,"href":i.link}},[_c(VImg,{staticClass:"d-block ml-auto mr-auto",class:{ 'zoom-efect': hover },attrs:{"src":i.img,"max-width":"100px"}}),_c('h1',{staticClass:"font-weight-regular"},[_vm._v(_vm._s(i.title))]),_c('h4',{staticClass:"font-weight-regular subtitle-1"},[_vm._v(" "+_vm._s(i.text)+" ")])],1)]}}],null,true)})],1)}),1)],1)],1)],1),_c(VDialog,{attrs:{"max-width":"640px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard)],1),_vm._m(0)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"svg-border-waves"},[_c('img',{attrs:{"src":require("@/assets/img/wave2.svg")}})])
}]

export { render, staticRenderFns }