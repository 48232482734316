<template>
  <v-container class="grey lighten-5">
    <v-row justify="center">
      <v-col sm="5" offset-sm="3" md="3" offset-md="0">
        <v-row justify="center">
          <v-img
            alt="Exorom LOGO"
            class="shrink mr-2"
            contain
            src="@/assets/img/logo.jpg"
            transition="scale-transition"
            width="80"
          />

          <h2 class="my-15">Sign in</h2>
        </v-row>

        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="E-mail"
            required
            outlined
          ></v-text-field>

          <v-text-field
            v-model="password"
            :type="value ? 'password' : 'text'"
            :rules="nameRules"
            label="Password"
            :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="() => (value = !value)"
            required
            outlined
          ></v-text-field>

          <v-btn color="primary" large style="width: 100%" @click="login">
            <v-progress-circular
              v-if="progress"
              indeterminate
              color="white"
            ></v-progress-circular>
            <div v-if="progress == false">Sign in</div>
          </v-btn>
          <v-btn color="black" large style="width: 100%" class="mt-5" text>
            Reset Password
          </v-btn>

          <v-btn large style="width: 100%" @click="login" class="mt-5">
            New Account
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
var Parse = require("parse");

export default {
  components: {},
  data: () => ({
    value: String,
    progress: false,
    nameRules: [(v) => !!v || "Password is required"],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
  }),
  methods: {
    login() {
      let email = this.email;
      let password = this.password;
      this.progress = true;
      Parse.User.logIn(email, password)
        .then(() => {
          window.location.href = "/";
        })
        .catch((e) => {
          alert(e.message);
          this.progress = false;
        });
    },
  },

  mounted: function () {
    var title = "Sign in - exorom";

    document.title = title;
  },
};
</script>