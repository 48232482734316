<template>

<Register/>

</template>


<script>
import Register from '@/components/auth/Register.vue';
export default{
    components:{
    Register:Register
}


}


</script>