import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c('BreadCrumb',{attrs:{"items":_vm.breadcrumbs}}),_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"12"}},[_c('p',{staticClass:"text-center"},[_vm._v(" Share Roadmap ")])]),_c(VBtnToggle,{attrs:{"mandatory":""}},[_c(VBtn,{attrs:{"dark":"","href":_vm.wapurl,"icon":"","color":"accent"}},[_c(VIcon,{attrs:{"color":"accent"}},[_vm._v("mdi-whatsapp")])],1),_c(VBtn,{attrs:{"href":_vm.linkedin}},[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-linkedin")])],1),_c(VBtn,{attrs:{"href":_vm.fburl}},[_c(VIcon,{attrs:{"color":"secondary"}},[_vm._v("mdi-facebook")])],1)],1)],1),_c('h1',{staticClass:"text-center mt-10 mb-10"},[_vm._v(" Datascience Roadmap ")]),_c(VTimeline,{attrs:{"align-top":"","dense":_vm.$vuetify.breakpoint.smAndDown}},_vm._l((_vm.items),function(item,i){return _c(VTimelineItem,{key:i,attrs:{"color":"primary","large":"","icon":item.icon}},[_c(VCard,{staticClass:"mx-10 rounded-xl mx-auto",attrs:{"color":"white","elevation":"1","max-width":"600"}},[_c(VCardTitle,{staticClass:"text-h6 mx-1"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c(VCardText,{staticClass:"white text--primary"},[_c('p',[_vm._v(" "+_vm._s(item.content))])]),_c(VBtn,{attrs:{"color":"secondary","text":"","href":item.link}},[_vm._v(" Resources ")])],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }