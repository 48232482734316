<template>
  <hello-world />
</template>

<script>
  import HelloWorld from '../components/HelloWorld'

  export default {
    name: 'Home',

    components: {
      HelloWorld,
    },

    metaInfo() {
        return { 
            title: "ExoRoadmap",
            meta: [
                { name: 'DataScience Roadmap', content:  'Datascience Roadmap'},
                { property: 'Programming Roadmap', content: "Programming Roadmap"},
               
            ]
        }
    }
  }
</script>
