import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loaded==false)?_c(VRow,{staticClass:"fill-height",attrs:{"align-content":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"6"}},[_c(VProgressLinear,{attrs:{"color":"accent","indeterminate":"","rounded":"","height":"6"}})],1)],1):_vm._e(),_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"12"}},[_c('p',{staticClass:"text-center"},[_vm._v(" Share resources ")])]),_c(VBtnToggle,{attrs:{"mandatory":""}},[_c(VBtn,{attrs:{"dark":"","href":_vm.wapurl,"icon":"","color":"accent"}},[_c(VIcon,{attrs:{"color":"accent"}},[_vm._v("mdi-whatsapp")])],1),_c(VBtn,{attrs:{"href":_vm.linkedin}},[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-linkedin")])],1),_c(VBtn,{attrs:{"href":_vm.fburl}},[_c(VIcon,{attrs:{"color":"secondary"}},[_vm._v("mdi-facebook")])],1)],1)],1),_c(VContainer,{staticClass:"grey lighten-5"},[(!_vm.certificates.length==0)?_c('section',{staticClass:"mb-32 text-gray-800 text-center"},[_c('h2',{staticClass:"text-3xl font-bold mb-12 mt-5"},[_vm._v("Certificates")]),_c('ResourceList',{attrs:{"rsrces":_vm.certificates}})],1):_vm._e(),(!_vm.books.length==0)?_c('section',{staticClass:"mb-32 text-gray-800 text-center"},[_c('h2',{staticClass:"text-3xl font-bold mb-12 mt-5"},[_vm._v("Books and Papers")]),_c('ResourceList',{attrs:{"rsrces":_vm.books}})],1):_vm._e(),(!_vm.tutorials.length==0)?_c('section',{staticClass:"mb-32 text-gray-800 text-center"},[_c('hr',{staticClass:"mt-12",attrs:{"color":"#E0E0E0"}}),_c('h2',{staticClass:"text-3xl font-bold mb-12 mt-5"},[_vm._v("Tutorials")]),_c('ResourceList',{attrs:{"rsrces":_vm.tutorials}})],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }