<template>
  <v-row justify="center" class="mt-10">
  <v-breadcrumbs :items="items" >
    <template v-slot:divider>
      <v-icon color="primary">mdi-forward</v-icon>
    </template>
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item color="primary"
        :href="item.url"
        :disabled=false
        >
    
        {{ item.name.toUpperCase() }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</v-row>
</template>

<script>

export default{
    props:['items']

}
</script>