<template>

<div>
  <Landing/>

</div>
  </template>
  
  <script>
  import Landing from './Landing.vue'

  
  export default {
  
    components: {
      Landing
      
  
    }
  ,
  
  
  
  };
  </script>
  