<template>
  <div>
    <v-row justify="center">
    <v-col md="12" cols="12" lg="12"  v-for="singleRes in rsrces" :key="singleRes.id">
        <SingleResource v-bind:singleRes="singleRes"/>
</v-col>

</v-row>
</div>
</template>
<script>
import SingleResource from "../resource/SingleResource.vue"


export default {
  components:{
    SingleResource
  },

  props:['rsrces'],
  

  

}
</script>
