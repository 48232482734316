<template>
  <v-app>
    <v-app-bar
      app
      color="white"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="Exorom LOGO"
          class="shrink mr-2"
          contain
          src="@/assets/img/logo.jpg"
          transition="scale-transition"
          width="80"
        />
<h3 class="primary--text">ExoRom</h3>
       
      </div>

      <v-spacer></v-spacer>

      
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
    <Footer/>
  </v-app>
</template>

<script>
import Footer from './components/Footer.vue'
export default {
  name: 'App',
  components:{
    Footer:Footer
  }

 
};
</script>
