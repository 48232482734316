<template>
  <v-footer dark padless>
    <v-card flat tile class="white white--text text-center">
      <v-card-text>
        <v-btn v-for="(icon, i) in icons" :key="i" class="mx-4 primary--text" :href="icon.link" target="_blank" icon>
          <v-icon size="24px">{{ icon.text }}</v-icon>
        </v-btn>
      </v-card-text>

      <v-card-text class="white--text pt-0">
      </v-card-text>
      <v-divider></v-divider>
    </v-card>
  </v-footer>
</template>
  
<style scoped>
.v-card {
  width: 100%;
}
</style>
  
<script>
import config from  "../config"
export default {
  methods: {
    getSocials(name, link) {
      axios
        .get(
          config.apiURL + "/api/general/name/" + name

        )
        .then((response) => {
          link = response.data[0].url;

          

        }).catch(error => {
         
        });

    },


    getAll() {
      for (var key in this.icons) {
        this.getSocials(this.icons[key].name,this.icons[key].link)
       
      }

    },

  },

  data: () => ({
    icons: [
      
      {
        name: "twitter",
        text: "mdi-twitter",
        link: "https://twitter.com/iasaman_rhzai",
      },
      {
        name: "linkedin",
        text: "mdi-linkedin",
        link: "https://linkedin.com/company/soiwork",
      },
      {
        name: "instagram",
        text: "mdi-instagram",
        link: "https://instagram.com/exoroadmap",
      },
    ],
  }),


 



  
  
};
</script>

