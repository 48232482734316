import Vue from 'vue'
import Vuex from 'vuex'
import config from '../config'
var Parse = require('parse')


Parse.serverURL = config.serverURL; // This is your Server URL
Parse.initialize(
    config.appID,
    config.jsKey,
    config.master_key

);




Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    socket: null

},
  getters: {
    isLoggedIn: state => state.token,
    authStatus: state => state.status,
    getUser: (state) => {
      return VueJwtDecode.decode(state.token)

  }
  ,
  getSocket: state => state.socket,
  },
  mutations: {

    auth_request(state) {
      state.status = 'loading'
  },
  auth_success(state, token, user) {
      state.status = 'success'
      state.token = token
      state.user = user

  },
  auth_error(state) {
      state.status = 'error'
  },
  logout() {
      Parse.User.logOut()
  },

  ASSIGN_SOCKET: (state, payload) => {
      state.socket = payload;
  },
  },
  actions: {
    logout({ commit }) {
      return new Promise((resolve) => {
          commit('logout')
          localStorage.removeItem('token')
          delete axios.defaults.headers.common['Authorization']
          resolve()
      })
  },


  assignSocket: (context, payload) => {
      context.commit('ASSIGN_SOCKET', payload);
  },

  },
  modules: {
  }
})
