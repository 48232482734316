<template>
    <div>
    <NotFound/>
</div>


</template>

<script>
import NotFound from  '../components/general/404component.vue'
import BreadCrumb from "../components/general/BreadCrumb.vue"
export default {
    components:{
        NotFound:NotFound,
        BreadCrumb:BreadCrumb
    },
    data(){
        return {
        items: [
        { name: 'Home', url: '/' },
        { name: 'Not Found', url: `/notFound` },

      ]
    }
}


}


</script>