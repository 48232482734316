<template>
<div >

  <v-row
  class="fill-height"
  align-content="center"
  justify="center"
  v-if="loaded==false"
>
  
  <v-col cols="6">
    <v-progress-linear
      color="accent"
      indeterminate
      rounded
      height="6"
    ></v-progress-linear>
  </v-col>
</v-row>
  <v-row
        align="center"
        justify="center"
      >
        <v-col cols="12">
          <p class="text-center">
            Share resources
          </p>
        </v-col>
        <v-btn-toggle
          
          mandatory
        >
          <v-btn
         
      dark
      :href="wapurl"
      icon
      color="accent"
     
  
          >
            <v-icon   color="accent">mdi-whatsapp</v-icon>
          </v-btn>
         
          <v-btn     :href="linkedin">
            <v-icon   color="primary">mdi-linkedin</v-icon>
          </v-btn>
          <v-btn   :href="fburl">
            <v-icon color="secondary">mdi-facebook</v-icon>
          </v-btn>

          
        </v-btn-toggle>
      </v-row>
  <v-container class="grey lighten-5">
    
    <section class="mb-32 text-gray-800 text-center" v-if="!certificates.length==0">
        <h2 class="text-3xl font-bold mb-12 mt-5">Certificates</h2>
     
          <ResourceList v-bind:rsrces="certificates"/>

      </section>
      <section class="mb-32 text-gray-800 text-center" v-if="!books.length==0">
      <h2 class="text-3xl font-bold mb-12 mt-5">Books and Papers</h2>
      
        <ResourceList  v-bind:rsrces="books"/>

 
  
    </section>

    <section class="mb-32 text-gray-800 text-center" v-if="!tutorials.length==0">
      <hr color="#E0E0E0" class="mt-12"/>
        <h2 class="text-3xl font-bold mb-12 mt-5">Tutorials</h2>
        
            <ResourceList v-bind:rsrces="tutorials"/>
     
     
      </section>
     
      </v-container>
    </div>
</template>
<script>
import ResourceList from "../resource/ResourceList.vue"
import config from "../../../config";
import axios from "axios";
export default {
  components:{
    ResourceList
  },
props:['name','field'],
data(){
    return{
      certificates:[],
      tutorials:[],
      books:[],
      url:config.apiURL+"/api/rsrc/type/tutorial/"+this.name,
      wapurl:"https://api.whatsapp.com/send?text=https://exorom.existange.com/"+this.field+"/"+this.name,
      fburl:"https://www.facebook.com/sharer/sharer.php?u=https://exorom.existange.com/"+this.field+"/"+this.name,
      linkedin:"https://www.linkedin.com/sharing/share-offsite/?url=https://exorom.existange.com/"+this.field+"/"+this.name,
      loaded:false

    }
  },

methods:{
 
    getTut(){
        axios
        .get(
          this.url,
          {
            headers:{
              'Access-Control-Allow-Origin': '*',
                'Content-type': 'application/json',
            }
          }

        )
        .then((response) => {
          this.tutorials = response.data;
          this.loaded = true
          

        }).catch(error=>{
      
 });

    },
    getBooks(){
        axios
        .get(
          config.apiURL+"/api/rsrc/type/books/"+this.name

        )
        .then((response) => {
          this.books = response.data;
       

        }).catch(error=>{
     
 });

    },
    getcert(){
        axios
        .get(
          config.apiURL+"/api/rsrc/type/certificate/"+this.name

        )
        .then((response) => {
          this.certificates = response.data;
        


        }).catch(error=>{
       
 });

    }

  },
  computed: {


  },

  created(){
    this.getcert();
    this.getBooks();
    this.getTut(); 
   
   

  }
 
  
}
</script>
