<template>
  <v-container>
    <BreadCrumb v-bind:items="breadcrumbs"/>
    <v-row
    align="center"
    justify="center"
  >
    <v-col cols="12">
      <p class="text-center">
        Share Roadmap
      </p>
    </v-col>
    <v-btn-toggle
      
      mandatory
    >
      <v-btn
     
  dark
  :href="wapurl"
  icon
  color="accent"
 

      >
        <v-icon   color="accent">mdi-whatsapp</v-icon>
      </v-btn>
     
      <v-btn     :href="linkedin">
        <v-icon   color="primary">mdi-linkedin</v-icon>
      </v-btn>
      <v-btn   :href="fburl">
        <v-icon color="secondary">mdi-facebook</v-icon>
      </v-btn>

      
    </v-btn-toggle>
  </v-row>
    <h1 class="text-center mt-10 mb-10">
      Backend Developer Roadmap
       </h1>
  <v-timeline
    align-top
    :dense="$vuetify.breakpoint.smAndDown"
    
  >
    <v-timeline-item
      v-for="(item, i) in items"
      :key="i"
      color="primary"
    large
      :icon="item.icon"
    >
   
      <v-card
      color="white"
        class="mx-10 rounded-xl mx-auto"
        elevation="1"
       
      >
        <v-card-title class="text-wrap" >
         {{item.title}}
        </v-card-title>
        <v-card-text class="white text--primary">
          <p> {{ item.content }}</p>
          
        </v-card-text>
        <v-btn
        color="secondary"
        text
        :href="item.link"
      >
       Resources
      </v-btn>
      </v-card>
    </v-timeline-item>
  </v-timeline>
</v-container>
</template>

<script>
import BreadCrumb from "../../../components/general/BreadCrumb.vue"
  export default {
    components:{
      BreadCrumb:BreadCrumb
    },
    data: () => ({
      wapurl:"https://api.whatsapp.com/send?text=Backend Roadmap https://exorom.existange.com/backend",
      fburl:"https://www.facebook.com/sharer/sharer.php?u=https://exorom.existange.com/backend",
      linkedin:"https://www.linkedin.com/sharing/share-offsite/?url=https://exorom.existange.com/backend",
      
      breadcrumbs: [
        { name: 'Home', url: '/' },
        { name: 'Backend', url: `/web/be` },

      ],
      items: [
        {
          color: 'primary',
          icon: '1',
          title:'Markup and Styling',
          content:'HTML5,CSS,Debuggin markup, SASS Preprocessing, Bootstrap , Material Design, Javascript',
          link:'resources/markup'
        },
       
        
        {
          color: 'indigo',
          icon: '2',
          title:'Server Side Framework and languages',
          content:'Node.js, python(django), Ruby on Rails, .Net, Java,GO,Flask',
          link:'resources/serverside'
        },
        {
          color: 'indigo',
          icon: '3',
          title:'Database',
          content:'Relational and Non Relational Databases, Relational Database Examples are : SQL Server MySQL, Oracle , Postgre SQL and Non Relational Database examples are MongoDB, Cassandra, Redis, Neo4j',
          link:'resources/database'
        },

        {
          color: 'indigo',
          icon: '4',
          title:'Servers and Cloud Hosting',
          content:'AWS, Azure, Google Cloud, heroku, Digital Ocean, Apache, Nginx',
          link:'resources/cloud'
        },

        {
          color: 'indigo',
          icon: '5',
          title:'Version Control',
          content:'Git, Grunt, Xdebug, Subversion',
          link:'resources/vc'
        },
        {
          color: 'indigo',
          icon: '6',
          title:'Project Management Tools',
          content:'Basecamp, Jira, Trello , Redmine, Teamwork',
          link:'resources/pm'
          
        },
        {
          color: 'indigo',
          icon: '7',
          title:'Others',
          content:'Cybersecurity basics, Networking Basics, Software Testing,Rest API',
          link:'resources/bemisc'
          
        },
      
      ],
    }),
  }
</script>