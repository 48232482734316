import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"grey lighten-5"},[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"sm":"5","offset-sm":"3","md":"3","offset-md":"0"}},[_c(VRow,{attrs:{"justify":"center"}},[_c(VImg,{staticClass:"shrink mr-2",attrs:{"alt":"Exorom LOGO","contain":"","src":require("@/assets/img/logo.jpg"),"transition":"scale-transition","width":"80"}}),_c('h2',{staticClass:"my-15"},[_vm._v("Sign in")])],1),_c(VForm,{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VTextField,{attrs:{"rules":_vm.emailRules,"label":"E-mail","required":"","outlined":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c(VTextField,{attrs:{"type":_vm.value ? 'password' : 'text',"rules":_vm.nameRules,"label":"Password","append-icon":_vm.value ? 'mdi-eye' : 'mdi-eye-off',"required":"","outlined":""},on:{"click:append":() => (_vm.value = !_vm.value)},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c(VBtn,{staticStyle:{"width":"100%"},attrs:{"color":"primary","large":""},on:{"click":_vm.login}},[(_vm.progress)?_c(VProgressCircular,{attrs:{"indeterminate":"","color":"white"}}):_vm._e(),(_vm.progress == false)?_c('div',[_vm._v("Sign in")]):_vm._e()],1),_c(VBtn,{staticClass:"mt-5",staticStyle:{"width":"100%"},attrs:{"color":"black","large":"","text":""}},[_vm._v(" Reset Password ")]),_c(VBtn,{staticClass:"mt-5",staticStyle:{"width":"100%"},attrs:{"large":""},on:{"click":_vm.login}},[_vm._v(" New Account ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }