<template>
  <v-container>
    <BreadCrumb v-bind:items="breadcrumbs"/>
    <v-row
    align="center"
    justify="center"
  >
    <v-col cols="12">
      <p class="text-center">
        Share Roadmap
      </p>
    </v-col>
    <v-btn-toggle
      
      mandatory
    >
      <v-btn
     
  dark
  :href="wapurl"
  icon
  color="accent"
 

      >
        <v-icon   color="accent">mdi-whatsapp</v-icon>
      </v-btn>
     
      <v-btn     :href="linkedin">
        <v-icon   color="primary">mdi-linkedin</v-icon>
      </v-btn>
      <v-btn   :href="fburl">
        <v-icon color="secondary">mdi-facebook</v-icon>
      </v-btn>

      
    </v-btn-toggle>
  </v-row>
    <h1 class="text-center mt-10 mb-10">
     Datascience Roadmap
      </h1>
  <v-timeline
    align-top
    :dense="$vuetify.breakpoint.smAndDown"
    
  >
    <v-timeline-item
      v-for="(item, i) in items"
      :key="i"
      color="primary"
     large
      :icon="item.icon"
    >
   
      <v-card
      color="white"
        class="mx-10 rounded-xl mx-auto"
        elevation="1"
        max-width="600"
      >
        <v-card-title class="text-h6 mx-1">
         {{item.title}}
        </v-card-title>
        <v-card-text class="white text--primary">
          <p> {{ item.content }}</p>
          
        </v-card-text>
        <v-btn
        color="secondary"
        text
        :href="item.link"
      >
       Resources
      </v-btn>
      </v-card>
    </v-timeline-item>
  </v-timeline>
</v-container>
</template>

<script>
import BreadCrumb from "../components/general/BreadCrumb.vue"
export default {
  components:{
      BreadCrumb:BreadCrumb
    },
    data: () => ({
      wapurl:"https://api.whatsapp.com/send?text=DataScience Roadmap https://exorom.existange.com/datascience",
      fburl:"https://www.facebook.com/sharer/sharer.php?u=https://exorom.existange.com/datascience",
      linkedin:"https://www.linkedin.com/sharing/share-offsite/?url=https://exorom.existange.com/datascience",

      breadcrumbs: [
        { name: 'Home', url: '/' },
        { name: 'Datascience', url: `/datascience` },

      ],
      items: [
        {
          color: 'primary',
          icon: '1',
          title:'Mathematics',
          content:'Linear Algebra ,Regression,Analytics Geometry, Classification, Density Estimation',
          link:'/datascience/resources/mathematics'
        },
        {
          color: 'purple darken-1',
          icon: '2',
          title:'Probability',
          content:'Discrete Distribution ,Normal Distribution,Continuos Distribution,Introduction to Probability ,1D Random Variable,Function of One Random Variable,Joint Probability Distribution',
          link:'/datascience/resources/probability'
        },
        {
          color: 'green lighten-1',
          icon: '3',
          title:'Statistics',
          content:'Data Description,Random Samples,Sampling Distribution,Reliability Engineering',
          link:'/datascience/resources/statistics'
        },
        {
          color: 'indigo',
          icon: '4',
          title:'Progamming',
          content:'Python, R, Python libraries including: Pandas, Beautiful Soup, Numpy , Scipy',
          link:'/datascience/resources/database'
        },
        {
          color: 'indigo',
          icon: '5',
          title:'Database',
          content:'Relational and Non Relational Databases, Relational Database Examples are : SQL Server MySQL, Oracle , Postgre SQL and Non Relational Database examples are MongoDB, Cassandra, Redis, Neo4j',
          link:'/datascience/resources/database'
        },

        {
          color: 'indigo',
          icon: '6',
          title:'Machine Learning',
          content:'How Models Work?,Learning Basic Data Exploration, How to Fit ML Model, What are Random Forests, What is difference between Underfitting and Overfitting, How to validate your model',
          link:'/datascience/resources/ml'
        },
        {
          color: 'indigo',
          icon: '7',
          title:'Deep Learning',
          content:'What is difference between supervised learning and unsupervised learning and what are their algorithms? learn the libraries including tensorflow, pytorch and keras',
          link:'/datascience/resources/dl'
          
        },

        {
          color: 'indigo',
          icon: '8',
          title:'Natural Language Processing(NLP)',
          content:'popular tools for NLP include Tensorflow and python library of NLTK. ',
          link:'/datascience/resources/nlp'
        },

        {
          color: 'indigo',
          icon: '9',
          title:'Digital Image Processing',
          content:'popular tools for Image Processing include Tensorflow and OpenCV. ',
          link:'/datascience/resources/dip'
        },

        {
          color: 'indigo',
          icon: '10',
          title:'Recommender Systems',
          content:'popular tools for Image Processing include Tensorflow and Lenskit. Important concepts of recommender systems are learning types of recommender systems such as collaborative,Content based and Knowledge based',
          link:'/datascience/resources/rs'
        },

        {
          color: 'indigo',
          icon: '11',
          title:'Data Visualization',
          content:'popular tools to start data visualization career include: Qlik View,Qlik Sense,Tableau,Oracle BI',
          link:'/datascience/resources/visualization'
          
        },
      ],
    }),
  }
</script>