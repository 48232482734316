import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VHover,{attrs:{"close-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c(VCard,{staticClass:"mx-auto rounded-xl",attrs:{"width":"900px","outlined":"","elevation":"1","rounded":""}},[_c(VListItem,[_c('p',{staticClass:"font-weight-bold mx-5 my-5"},[_vm._v(" "+_vm._s(_vm.singleRes.name)+" ")])]),_c(VListItem,[_c(VCardText,{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.singleRes.content)+" ")])],1),_c('div',{staticClass:"text-sm-center text-md-left text-xl-left text-lg-left"}),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"rounded":"","dark":"","color":"primary","small":"","href":_vm.singleRes.link}},[_vm._v(" Visit Link "),_c(VIcon,{attrs:{"right":"","dark":""}},[_vm._v(" mdi-link ")])],1)],1)],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }