import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '072ac8',
            secondary: '#1A237E',
            accent: '#00ACC1',
            error: 'ff0054',
            white:'f0f6f6',
            grey:"979dac",
            yellow:"fcf300"
          },
        },
      },
});
