<template>
    <div>
    
    <Hero/>
  
    
  
    </div>
  
  </template>
  
  <script>
  import Hero from "./Hero.vue";


  
  export default {
    mounted() {
  
  window.addEventListener("scroll", this.handleScroll);
  
  },
  
    components: {
      Hero,
    
      
  
    },
    methods:{
  
    }
  
  
  };
  </script>
  
  <style>
  </style>
  