<template>
  <div>
    <BreadCrumb v-bind:items="items" />
    <ResourceComponent v-bind:name="name" v-bind:field="field" />
  </div>
</template>
<script>
import ResourceComponent from "../components/general/resource/ResourceComponent.vue"

import config from "../config";
import axios from "axios";
import BreadCrumb from "../components/general/BreadCrumb.vue"

export default {
  components: {
    ResourceComponent,
    BreadCrumb
  },
  data() {
    return {
      name: this.$route.params.name,
      field: this.$route.params.field,
      items: [
        { name: 'Home', url: '/' },
        { name: this.$route.params.field, url: `/${this.$route.params.field}` },
        { name: this.$route.params.name, url: `/${this.$route.params.field}/resources/${this.$route.params.name}` },
      ]

    }
  },
  methods: {
    getRsrces() {
      axios
        .get(
          config.apiURL + "/api/rsrc/" + this.$route.params.name

        )
        .then((response) => {
          this.rsrces = response.data;

        }).catch(error => {
        });

    },
    getTut() {
      axios
        .get(
          config.apiURL + "/api/rsrc/type/tutorial/" + this.$route.params.name

        )
        .then((response) => {
          this.tutorials = response.data;


        }).catch(error => {

        });

    },
    getBooks() {
      axios
        .get(
          config.apiURL + "/api/rsrc/type/books/" + this.$route.params.name

        )
        .then((response) => {
          this.books = response.data;


        }).catch(error => {

        });

    },
    getcert() {
      axios
        .get(
          config.apiURL + "/api/rsrc/type/certificate/" + this.$route.params.name

        )
        .then((response) => {
          this.certificates = response.data;

        }).catch(error => {

        });
    }
  },

  
  mounted: function () {
    var title = this.$route.params.name
    console.log(title)
    document.title = title


  }


}

</script>