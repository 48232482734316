import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c(VAppBar,{attrs:{"app":"","color":"white","dark":""}},[_c('div',{staticClass:"d-flex align-center"},[_c(VImg,{staticClass:"shrink mr-2",attrs:{"alt":"Exorom LOGO","contain":"","src":require("@/assets/img/logo.jpg"),"transition":"scale-transition","width":"80"}}),_c('h3',{staticClass:"primary--text"},[_vm._v("ExoRom")])],1),_c(VSpacer)],1),_c(VMain,[_c('router-view')],1),_c('Footer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }