export default {
  apiURL:'https://meo.existange.com',
  serverURL: "https://parseapi.back4app.com",
  appID: 'dliSgKAM1OPEYrgXo9D7GuU0FftUBxHP0gxccdqz',
  jsKey: 'DkudGftqPuCwpjsJERmuDFnK7bi7svNEfiMa0lO2',
  wss: 'wss://soiwork.b4a.io',
  master_key: 'TMT6mgRc1zET8T8E61BCLfYmEzbmvcQoIhV0WdFG',


}


