<template>

    <Login/>
    
    </template>
    
    
    <script>
    import Login from '@/components/auth/Login.vue';
    export default{
        components:{
        Login:Login
    }
    
    
    }
    
    
    </script>