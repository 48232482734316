<template>
  <div>
    <v-hover v-slot="{ hover }" close-delay="200">
      <v-card
        class="mx-auto rounded-xl"
        width="900px"
        outlined
        elevation="1"
        rounded
      >
        <v-list-item>
          <p class="font-weight-bold mx-5 my-5" >
            {{ singleRes.name }}
          </p>
        </v-list-item>
        <v-list-item>
          <v-card-text class="text-left">
            {{ singleRes.content }}
          </v-card-text>
        </v-list-item>
        <div class="text-sm-center text-md-left text-xl-left text-lg-left">
       
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            rounded
            dark
            color="primary"
            small
            v-bind:href="singleRes.link"
          >
            Visit Link
            <v-icon right dark> mdi-link </v-icon>
          </v-btn>
      
     <!--     <v-btn
          rounded
          dark
          color="secondary"
          outlined
          small
         >
         useful
          <v-icon>mdi-thumb-up</v-icon>
        </v-btn>
     -->
        </v-card-actions>
      </v-card>
    </v-hover>
  </div>
</template>


<script>
//import AddCm from '@/components/general/comment/AddCm'

export default {
  props: ["singleRes"],
  components:{
   // AddCm:AddCm

  },

  data: () => ({
    show: false,
  }),
  methods: {},
};
</script>
